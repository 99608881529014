import React from 'react'
import AboutSection from './AboutSection'
import WhyChooseUs from './WhyChooseUs'
import Services from './Services'
import ContactComponent from './ContactComponent'
import Header from './Header'

export default function Home() {
  return (
   <>
   
       <AboutSection/>
    <WhyChooseUs/>
    <Services/>

    <ContactComponent/>
   
   </>
  )
}
