import logo from './logo.svg';
import './App.css';
import AboutSection from './Components/AboutSection';
import TopBar from './Components/TopBar';
import Header from './Components/Header';
import WhyChooseUs from './Components/WhyChooseUs';
import Services from './Components/Services';
import ContactComponent from './Components/ContactComponent';
import Footer from './Components/Footer';
import Home from './Components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    <>
    <BrowserRouter>
    <TopBar/>
    <Header/>
    <Routes>
            <Route path="/" element={<Home/>} />

            <Route path="/about" element={<AboutSection />} />
          <Route path="/contact" element={<ContactComponent />} />
          <Route path="/services" element={<Services />} />
  </Routes>
      <Footer/>
  
      </BrowserRouter>
      </>

  );
}

export default App;
