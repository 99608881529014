import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" className="navbar-brand">
                  <h1 className="m-0 text-white"><i className="fa fa-user-tie me-2"></i>A2TECH</h1>
                </a>
                <p className="mt-3 mb-4">
                  
                At A2 Technologies , we are dedicated to transforming the digital landscape and driving innovation through cutting-edge technology solutions.                  </p>
                {/* <form action="">
                  <div className="input-group">
                    <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">Flat No. 1402, 14th Floor, Krishna paradise  100ft Road kalyan (E), Dist. Thane</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">support@a2technologies.in</p>
                  </div>
                  {/* <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+012 345 67890</p>
                  </div> */}
                  {/* <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                    <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                  </div> */}
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                  <Link to="/" className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                  <Link to="/about"className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                  <Link to="/services"className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                    {/* <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a>
                    <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a> */}
                   <Link to="/contact" className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                  <Link to="/" className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                  <Link to="/about"className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                  <Link to="/services"className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                    {/* <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a>
                    <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a> */}
                   <Link to="/contact" className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-white" style={{ background: '#061429' }}>
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div className="d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                <p className="mb-0">
                  &copy; <a className="text-white border-bottom" >A2Tech </a>. All Rights Reserved.
                  Designed by <a className="text-white border-bottom">Vishal Gupta</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
