import React from 'react';

const services = [
  {
    icon: 'fa-shield-alt',
    title: 'Infra management and services',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fa-chart-pie',
    title: 'DevOps & Cloud Management',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fa-code',
    title: 'Web Development',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fab fa-android',
    title: 'Enterprise Application Development',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fa-search',
    title: 'Database Migration & Modernization Services',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },

  {
    icon: 'fa-search',
    title: 'Application modernization',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fa-search',
    title: 'Application maintenance and support',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  {
    icon: 'fa-search',
    title: 'Middleware services migration and management',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
  
  {
    icon: 'fa-search',
    title: 'Quality Assurance Services',
    description: 'Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed',
  },
];

const Services = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
          <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
        </div>
        <div className="row g-5">
          {services.map((service, index) => (
            <div key={index} className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay={`${0.3 + index * 0.3}s`}>
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  {/* <i className={`fa ${service.icon} text-white`} /> */}
                </div>
                <h4 className="mb-3">{service.title}</h4>
                {/* <p className="m-0">{service.description}</p> */}
                <a className="btn btn-lg btn-primary rounded" href="#">
                  <i className="bi bi-arrow-right" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
